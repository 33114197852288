import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../../components/Layout";
import ImageGallery from "react-image-gallery";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  data,
}) => {
    const images = data.galleryPhotos.edges.map((image) => ({
        original: image.node.childImageSharp.fluid.src,
        thumbnail: image.node.childImageSharp.fluid.src,
      }));
  return (
    <div>
      <section className="section section--gradient" style={{backgroundColor: "#2c2c2c", height: "120vh"}}>
        <div className="container ">
          <div className="section">

            <div className="columns">

              <div className="column is-12">
                <div className="content">
                <ImageGallery items={images} thumbnailPosition="left" loading="eager"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <IndexPageTemplate
        data={data}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql `
  query {
    galleryPhotos: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "gallery"}}, sort: {fields: base, order: ASC}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`


          